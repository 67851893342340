body {
  margin: 30px;
  background-color: lightblue;
  padding-top: 110px;
}

.nav-link:hover {
  color: #f22;
  background-color: #c3e2ed;
}
